import { doc } from 'firebase/firestore';
import type { BrandStatus, Brand } from '@tn/shared';
import cloneDeep from 'lodash/cloneDeep';

export const useBrandOnboardingStore = defineStore('brandOnboarding', () => {
  const db = useFirestore();
  const { $sentry } = useNuxtApp();
  const { brandUser } = useCurrentUserDetails();
  const brandId = ref<string | undefined>(brandUser?.brand?.id);
  const localBrandDetailsData: Ref<Partial<Brand> | null> = ref(null);

  const setBrandId = (newBrandId: string | undefined) => {
    if (!brandId.value) {
      brandId.value = newBrandId;
    }
  };

  const brandRef = computed(() => {
    if (!brandId.value) {
      return null;
    }

    return doc(db, 'brands', brandId.value);
  });

  const brandStatusRef = computed(() => {
    if (!brandId.value) {
      return null;
    }

    return doc(db, 'brandStatus', brandId.value);
  });

  const brand = useDocument<Brand>(brandRef);
  const brandStatus = useDocument<BrandStatus>(brandStatusRef);

  watch(
    brand,
    (newValue) => {
      if (newValue && brand.value) {
        localBrandDetailsData.value = cloneDeep(brand.value);
      }
    },
    { immediate: true, deep: true }
  );

  const localOnboardingData = ref<Partial<BrandStatus> | undefined>();

  watch(
    brandStatus,
    (newValue) => {
      if (newValue) {
        localOnboardingData.value = newValue;
      }
    },
    { immediate: true, deep: true }
  );

  const updateLocalBrandDetailsData = (updatedData: Partial<Brand>) => {
    if (localBrandDetailsData.value) {
      Object.assign(localBrandDetailsData.value, updatedData);
    } else {
      localBrandDetailsData.value = updatedData;
    }
  };

  const isLoadingData = computed(
    () => brand.pending.value || brandStatus.pending.value
  );

  const updateBrandStatus = async (
    updatedBrandStatus: Partial<BrandStatus>
  ) => {
    const { brandUser } = useCurrentUserDetails();
    const { headers } = authenticatedRequestHeaders();

    try {
      await $fetch(`/api/brands/${brandUser?.brand.id}/status`, {
        headers: headers,
        method: 'PATCH',
        body: updatedBrandStatus,
      });
    } catch (error: any) {
      $sentry.captureException(error);
      if (error?.statusMessage && error?.statusCode) {
        throw createError({
          statusCode: error.statusCode,
          statusMessage: error.statusMessage,
        });
      } else {
        throw error;
      }
    }
  };

  const saveOnboardingStepData = (data: Partial<BrandStatus>) => {
    if (localOnboardingData.value) {
      Object.assign(localOnboardingData.value, data);
    } else {
      localOnboardingData.value = data;
    }
  };

  const removeStepDataKey = (dataKey: string) => {
    const existingData = localOnboardingData.value?.onboarding;
    if (!existingData) {
      return;
    }
    if (dataKey in existingData) {
      delete (existingData as Record<string, any>)[dataKey];
      if (localOnboardingData.value) {
        localOnboardingData.value.onboarding = { ...existingData };
      }
    }
  };

  return {
    brandStatus,
    localBrandDetailsData,
    updateLocalBrandDetailsData,
    updateBrandStatus,
    localOnboardingData,
    saveOnboardingStepData,
    removeStepDataKey,
    setBrandId,
    isLoadingData,
  };
});
